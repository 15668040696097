<template>
    <div style="text-align: left;">
        <el-card>
            <div class="top">
                <div class="top_left">
                    <el-button-group>
                        <el-button size="mini" :type="itemAuidtStatus===null?'primary':''" @click="setAuidtStatus(null)">全部</el-button>
                        <el-button size="mini" :type="itemAuidtStatus===1?'primary':''" @click="setAuidtStatus(1)">待审核</el-button>
                        <el-button size="mini" :type="itemAuidtStatus===2?'primary':''" @click="setAuidtStatus(2)">已过审</el-button>
                        <el-button size="mini" :type="itemAuidtStatus===3?'primary':''" @click="setAuidtStatus(3)">被驳回</el-button>
                    </el-button-group>
                    <el-button-group class="m-l-30">
                        <el-button size="mini" :type="itemIsShelves===null?'primary':''" @click="setIsShelves(null)">全部</el-button>
                        <el-button size="mini" :type="itemIsShelves===false?'primary':''" @click="setIsShelves(false)">已上架</el-button>
                        <el-button size="mini" :type="itemIsShelves===true?'primary':''" @click="setIsShelves(true)">已下架</el-button>
                    </el-button-group>
                </div>
                <div class="top_Right">
                    <el-select v-model="enterpriseVideoAlbumId" placeholder="请选择分类筛选" size="mini" clearable @change="checkout">
                        <el-option v-for="item in classifyList" :key="item.id" :label="item.videoAlbumName" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-button class="m-l-30" size="mini" type="primary" icon="el-icon-plus" @click="goto()">发布视频</el-button>
                </div>
            </div>
            <div class="m-t-15">
                <template v-if="prlductData && prlductData.content && prlductData.content.length">
                    <div class="boxs">
                        <div v-for="(v,i) of prlductData.content" :key="i" class="items" @click="message()">
                            <div class="item">
                                <div class="box">
                                    <template>
                                        <template v-if="v.itemAuidtStatus==1">
                                            <span class="demo-warter-text" style="background-color: #2090FF;">待审核</span>
                                        </template>
                                        <template v-else-if="v.itemAuidtStatus==2">
                                            <template v-if="v.itemIsShelves">
                                                <span class="demo-warter-text" style="background-color: #EFA21E;">已上架</span>
                                            </template>
                                            <template v-else>
                                                <span class="demo-warter-text" style="background-color: #B1B1B1;">未上架</span>
                                            </template>
                                        </template>
                                        <template v-else-if="v.itemAuidtStatus==3">
                                            <span class="demo-warter-text" style="background-color: #FF0000;">已驳回</span>
                                        </template>
                                    </template>
                                    <div class="boxRight">
                                        <div class="boxRightItem">
                                            <img src="@/assets/icon/icon_collect.png" alt="">
                                            <div class="line-1">{{v.itemCollectNum}}</div>
                                        </div>
                                        <div class="boxRightItem">
                                            <img src="@/assets/icon/icon_share.png" alt="">
                                            <div class="line-1">{{v.itemShareNum}}</div>
                                        </div>
                                        <div class="boxRightItem">
                                            <img src="@/assets/icon/icon_like.png" alt="">
                                            <div class="line-1">{{v.itemFabulousNum}}</div>
                                        </div>
                                        <div class="boxRightItem">
                                            <img src="@/assets/icon/icon_comment.png" alt="">
                                            <div class="line-1">{{v.itemCommentNum}}</div>
                                        </div>
                                    </div>
                                    <img v-if="v.itemCover" :src="$oucy.ossUrl+v.itemCover" class="videoAlbumPhoto" />
                                    <div class="videoAlbumName">
                                        <div class="line-1 itemInfo">{{v.itemInfo}}</div>
                                        <div class="user df-ai-c m-t-10">
                                            <img :src="$oucy.ossUrl+v.itemCreateuserAvata" alt="" class="itemCreateuserAvata"><span class="itemCreateuserName m-l-5">{{v.itemCreateuserName}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="m-t-15">
                                    <el-button type="" size="mini" @click="message()">修改</el-button>
                                    <!-- <el-button type="" size="mini" @click="message" v-if="v.itemAuidtStatus==2">下架</el-button> -->
                                    <!-- <el-button type="" size="mini" @click="message" v-else>上架</el-button> -->
                                    <el-button type="" size="mini" @click="message()">删除</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div style="margin-bottom: 25px;text-align: center;margin-top: 50px;" v-else>
                    <img src="@/assets/icon/notClassif.png" style="width:200px">
                    <div class="f14 c-2 m-b-20">
                        当前分类还没有视频哦！
                    </div>
                    <el-button type="primary" size="mini" @click="goto">立即添加</el-button>
                    <el-button type="default" size="mini" @click="oucy.back()">返回</el-button>
                </div>
            </div>
            <el-pagination class="zdfy" v-if="prlductData && prlductData.content && prlductData.content.length" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
        </el-card>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { ticket, enterprisevideoalbum } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"
export default {
    name: "Management",
    data() {
        return {
            activeName: 'second',
            enterpriseId: null,
            ticketIsPublish: null,
            isEffective: null,
            ticketByEnterprise: [],
            distributionVisible: false,
            distributionVisible2: false,
            rules: {

                ticketDeadTime: [
                    { required: true, message: '请选择失效时间', trigger: 'blur' },
                ]
            },
            form: {
                enterpriseId: null,
                ticketId: null,
                title: null,
                min: 1,
                "ticketDeadTime": "",
                "ticketPubTotal": null,
                "old_ticketDeadTime": new Date(),

            },
            options: {
                disabledDate: (time) => {
                    return time.getTime() < new Date(this.form.old_ticketDeadTime).getTime()
                }
            },
            v: {}, //缓存优惠券
            visible: false,
            maxlength: -1,
            max: -1,
            ticketNum: 1,
            userIdList: [],
            hasAjax: true,
            ticketGetList: {},
            start: 0,


            prlductData: {},
            limit: 20,
            start: 0,
            list: [],
            classifyList: [],
            enterpriseVideoAlbumId: null, //视频分类编码
            itemAuidtStatus: null, // 审核状态 1待审核 2审核通过 3审核驳回
            itemIsShelves: null, //    是 true 上架/ false 下架
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        this.enterpriseId = enterprise.id
        this.enterpriseVideoAlbumId = this.$route.query.enterpriseVideoAlbumId
        this.form.enterpriseId = enterprise.id

        this.getAllEnterpriseVideoAlbumsClient()
        this.queryAllVideoItemForPage()
    },
    methods: {
        getAllEnterpriseVideoAlbumsClient() {
            enterprisevideoalbum.getAllEnterpriseVideoAlbumsClient({ enterpriseId: this.enterpriseId }).then(res => {
                this.classifyList = res
            })
        },
        queryAllVideoItemForPage() {
            enterprisevideoalbum.queryAllVideoItemForPage({
                limit: this.limit,
                start: this.start,
                enterpriseId: this.enterpriseId,
                enterpriseVideoAlbumId: this.enterpriseVideoAlbumId,
                itemAuidtStatus: this.itemAuidtStatus,
                itemIsShelves: this.itemIsShelves,
            }).then(res => {
                this.prlductData = res
            })
        },

        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            this.queryAllVideoItemForPage()
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            this.queryAllVideoItemForPage()
        },
        checkout() {
            this.start = 0
            this.prlductData = null
            this.queryAllVideoItemForPage()
        },
        setAuidtStatus(v) {
            this.itemAuidtStatus = v
            this.checkout()
        },
        setIsShelves(v) {
            this.itemIsShelves = v
            this.checkout()
        },
        delMessageBox(fu, v, i, text = null) {
            this.$confirm(`确定要删除该视频分类[${v.videoAlbumName}]吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this[fu](v, i)
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });

            }).catch((err) => {
                console.log(err)
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        message(message = '开发中，敬请期待！') {
            this.$message({
                type: 'success',
                message: message
            });
        },
        goto() {
            this.$oucy.go('/enter/videoCreate?enterpriseVideoAlbumId=' + this.enterpriseVideoAlbumId)
        },


        handleClick(tab, event) {
            console.log(tab, event);
        },
        initList() {
            this.ticketIsPublish = null
            this.isEffective = null
            this.hasAjax = true
            this.ticketByEnterprise = []
            this.getTicketByEnterprise()
        },
        setTicketIsPublish(v) {
            if (this.ticketIsPublish === v) {
                // return
                this.ticketIsPublish = null
            } else {
                this.ticketIsPublish = v
            }
            this.hasAjax = true
            this.ticketByEnterprise = []
            this.getTicketByEnterprise()
        },
        setIsEffective(v) {
            if (this.isEffective === v) {
                // return
                this.isEffective = null
            } else {
                this.isEffective = v
            }
            this.hasAjax = true
            this.ticketByEnterprise = []
            this.getTicketByEnterprise()
        },
        getTicketByEnterprise() {
            // enterpriseId    企业编码        true    
            // ticketIsPublish 是否发布 false/未发布 true/已发布     false   
            // ticketKind  优惠券类型 0满减券 1现金券
            ticket.getTicketByEnterprise({ ticketIsPublish: this.ticketIsPublish, isEffective: this.isEffective, enterpriseId: this.enterpriseId, }).then(res => {
                this.hasAjax = false
                for (let v of res) {
                    v.ticketGetList = []
                    v.hasMore = true
                    v.ticketGetNoUsedNum = 0
                    v.ticketGetNum = 0
                    v.ticketGetUsedNum = 0
                    v.start = 0
                }
                this.ticketByEnterprise = res
            })
        },
        openTicketGetList(v, i) {
            v.ticketGetList = []
            v.start = 0
            v.hasMore = true
            this.queryTicketGetNum(v, i)
            this.queryTicketGetList(v, i)
        },
        queryTicketGetList(v, i) {

            ticket.queryTicketGetList({ ticketId: v.id, start: v.start, limit: 10 }).then(res => {
                if (res.content) {
                    v.ticketGetList.push(...res.content)
                }
                v.hasMore = !res.last
                    ++v.start
            })
        },
        queryTicketGetNum(v, i) {
            ticket.queryTicketGetNum({ ticketId: v.id }).then(res => {
                v.ticketGetNoUsedNum = res.ticketGetNoUsedNum || 0
                v.ticketGetNum = res.ticketGetNum || 0
                v.ticketGetUsedNum = res.ticketGetUsedNum || 0
            })
        },
        deleteTicket(v, i) {
            this.$oucy.confirm('确定删除当前优惠券？').then(res => {
                ticket.deleteTicket({ ticketId: v.id }).then(res => {
                    this.$message('操作成功')
                    this.ticketByEnterprise.splice(i, 1)
                })
            }).catch(err => {})
        },
        release(v, i) {
            this.$oucy.confirm(`确定“${v.ticketIsPublish?'取消开放':'开放领取'}”当前优惠券？`).then(res => {
                ticket.release({ ticketId: v.id }).then(res => {
                    this.$message('操作成功')
                    v.ticketIsPublish = !v.ticketIsPublish
                })
            }).catch(err => {})
        },
        batchGiveTickets(v, i) {
            this.$oucy.confirm(`确定赠送当前优惠券？`).then(res => {
                let data = {
                    ticketIdList: JSON.stringify(this.$oucy.getSrcList([this.v], 'id')),
                    ticketNum: this.ticketNum,
                    userIdList: JSON.stringify(this.$oucy.getSrcList(this.userIdList, 'id')),
                }
                ticket.batchGiveTickets(data).then(res => {
                    this.$message('操作成功')
                    this.distributionVisible2 = false
                    v.ticketGetTotal += this.ticketNum
                })
            }).catch(err => {})
        },
        openUpdateTicket(v, i) {
            this.v = v
            this.form.title = '修改:' + v.ticketName
            this.form.ticketPubTotal = 1
            this.form.ticketDeadTime = v.ticketDeadTime
            this.form.min = v.ticketPubTotal * 1 - v.ticketGetTotal * 1
            this.form.ticketId = v.id
            this.form.old_ticketDeadTime = v.ticketDeadTime
            this.distributionVisible = true
        },
        onSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.form.ticketPubTotal = this.form.ticketPubTotal * 1
                    this.updateTicket()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        },
        updateTicket() {
            this.$oucy.confirm(`确定${this.form.title}？`).then(res => {
                ticket.updateTicket(this.form).then(res => {
                    this.v.ticketPubTotal = this.form.ticketPubTotal + this.v.ticketPubTotal
                    this.v.ticketDeadTime = this.form.ticketDeadTime
                    this.distributionVisible = false
                })
            }).catch(err => {})
        },
        // 打开选择用户
        openDialogFormVisible(v, i) {
            this.v = v
            this.maxlength = v.ticketPubTotal * 1 - v.ticketGetTotal * 1
            if (this.maxlength > 0) {
                this.$refs.selectUserDialog.openDialogFormVisible()
            } else {
                this.$alert('已经赠送完' + v.ticketPubTotal + '张优惠券，如需赠送请先修改增加券库存量')
            }
        },
        // 选择用户回调
        callBack(list) {
            this.distributionVisible2 = true
            this.userIdList = list
            if (list.length > 1) {
                let names = ''
                for (let v of list) {
                    names += v.ltUserDetailFull.detailNickname + '；'
                }
                names += list.length + '位用户。'
            }
            this.max = ~~(this.maxlength / list.length)
            // this.enterApplyForm.userAuthId = v.id
            // this.selectObj = v
        },
    }
}
</script>
<style scoped lang="less">
.top {
    display: flex;
    justify-content: space-between;
}

.top_Right {
    display: flex;
    align-items: flex-start;
}

.top_Right_box {
    border-radius: 10px;
    padding: 22px 32px;
    cursor: pointer;
}

.top_Right_box+.top_Right_box {
    margin-left: 10px;
}

.top_Right_box_title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
}

.top_Right_box1 {
    background: #EAF3FF;

    .top_Right_box_title {
        color: #555C68;
    }
}

.top_Right_box2 {
    background: #FFF7EA;

    .top_Right_box_title {
        color: #E7801B;
    }
}

.top_Right_box3 {
    background: #FFF4F4;

    .top_Right_box_title {
        color: #F05353;
    }
}

.top_Right_box_info {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-top: 10px;
}




.top {
    text-align: center;
}

.c-10 {
    color: #2090FF;
}

.notData {
    text-align: center;
    color: #ccc;
    padding: 125px 0;
    font-size: 14px;
}


.boxs {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-left: -10px;
    margin-right: -10px;
}

.items {
    width: 16.666666666666668%;
    padding: 10px;
    box-sizing: border-box;
}

.item {
    box-sizing: border-box;
    width: 100%;

    text-align: center;
    /*border: 1px solid #EAEAEA;*/
    cursor: pointer;
}

.box {
    border-radius: 4px;
    background: #000;
    /*background: linear-gradient(180deg, rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.00) 79%, rgba(0, 0, 0, 0.34) 86%, rgba(0, 0, 0, 0.75));*/
    width: 100%;
    height: 0;
    padding-bottom: 150%;
    overflow: hidden;
    position: relative;
}

.videoAlbumName {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    /*height: 40px;*/
    /*line-height: 40px;*/
    color: #fff;
    text-align: left;
    background: linear-gradient(180deg, rgba(51, 51, 51, 0), rgba(51, 51, 51, .8));
    padding: 10px;
}

.notImg {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);

    .img {
        width: 80px;
    }

    z-index: 1;
}

.videoAlbumPhoto {
    position: absolute;
    left: 50%;
    top: 50%;
    max-height: 100%;
    max-width: 100%;
    transform: translate(-50%, -50%);
}

.demo-warter-text {
    font-size: 12px;
    color: #ffffff;
    border-radius: 0 0 4px 0;
    height: 20px;
    line-height: 20px;
    width: 50px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 10;
    text-align: center;
}

.boxRight {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 20;
}

.boxRightItem {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    color: #fff;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    /*padding-bottom: 10px;*/
    width: 56px;
    height: 56px;
    font-size: 12px;
    border-radius: 2px;

    img {
        width: 24px;
        height: 24px;
    }
}

.boxRightItem+.boxRightItem {
    margin-top: 10px;
}

.itemCreateuserAvata {
    width: 21px;
    height: 21px;
    border-radius: 21px;
}

.itemCreateuserName {
    font-size: 12px;
    max-width: 60%;
}

.itemInfo {
    font-size: 12px;
    max-width: 70%;
}

@media screen and (max-width: 1350px) {
    .myIcon {
        display: none;
    }
}

@media screen and (max-width: 1600px) {
    .items {
        width: 20%;
    }
}

@media screen and (max-width: 1400px) {
    .items {
        width: 25%;
    }
}
</style>